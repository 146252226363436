<template>
    <div class="footer mt-5 bg-white py-4 d-flex flex-lg-column" id="kt_footer">
            <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div class="text-dark order-2 order-md-1">
                    <span class="text-muted font-weight-bold mr-2">2022©</span>
                    <a href="Javascript:void(0)" target="_blank" class="text-dark-75 text-hover-primary">Kinnections</a>
                </div>
                <div class="nav nav-dark">
                    <a href="https://kinnections.com.au/about-us/" target="_blank" class="nav-link pl-0 pr-5">About</a>
                    <a href="https://kinnections.com.au/contact-us/" target="_blank" class="nav-link pl-0 pr-0">Contact</a>
                </div>
            </div>
        </div> 
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        
    },
})
</script>
