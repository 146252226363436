<template>
    <HeaderMobile></HeaderMobile>
    <div class="d-flex flex-column flex-root  vh100">
        <div class="d-flex flex-row flex-column-fluid page">
            <Sidebar></Sidebar>
            <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                <HeaderDesktop></HeaderDesktop>
                <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <router-view></router-view>
                </div>              
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderDesktop from '@/components/headers/HeaderDesktop.vue';
import HeaderMobile from '@/components/headers/HeaderMobile.vue';
import Sidebar from '@/components/sidebars/Sidebar.vue';
import Footer from '@/components/footer/Footer.vue';


export default {
    components: { Footer, HeaderDesktop, HeaderMobile, Sidebar },
    setup() {

    }
}  	
</script>
