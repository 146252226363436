<template>
        <div id="kt_header_mobile" class="header-mobile align-items-center header-mobile-fixed">
            <a href="index.html">
                <img src="@/assets/images/logo-mobile.png" class="max-h-75px" alt="" />
            </a>
            <div class="d-flex align-items-center">
                <button @click="menu_watcher++" class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span></span>
                </button>
                        <div @click="toggle"
                            aria-haspopup="true" aria-controls="overlay_menu"
                        class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2">
                            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{user.name}}</span>
                            <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
                                <span class="symbol-label font-size-h5 font-weight-bold">{{nameInitial(user.name)}}</span> 
                            </span>
                        </div>
                        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />

            </div>
            <!--end::Toolbar-->
        </div>
        <app-menu :menu_watcher="menu_watcher"></app-menu>
</template>
<script>
import { defineComponent, inject , ref, computed } from 'vue'
import Menu from 'primevue/menu';
import AppMenu from '../AppMenu.vue';
export default defineComponent({
    components: { Menu, AppMenu },
    setup() {
        const  { store } = inject('App')
        const menu_watcher = ref(1)
        const menu = ref();
        const items = ref([
            {
                label: 'Settings',
                command: () => {
                    localStorage.removeItem('token')
                    window.location = "/"
                }
            },
            {
                label: 'Logout',
                command: () => {
                    localStorage.removeItem('token')
                    window.location = "/"
                }
            }
        ]);

        function toggle(event)  {
            menu.value.toggle(event);
        }
        function nameInitial(string) {
            return string.charAt(0).toUpperCase();
        }
        
        return {
            user: computed(() => store.getters['UserModule/GET_USER']),
            toggle, nameInitial, menu, items, menu_watcher
        }
    },
})
</script>
