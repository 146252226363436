<template>
<div id="kt_header" class="header header-fixed">
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
        <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            <div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                <ul class="menu-nav">
                    <li class="menu-item menu-item-submenu menu-item-rel ">
                        <a href="javascript:;" class="fw-600  fs-16  text-dark-50  text-muted">
                         Dashboard <i class="pi pi-chevron-right pt-2 pl-2 pr-2"></i> 
                         <span v-if="route.name != 'Dashboard'">{{ route.name }}</span>
                         <span v-else>Home</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="topbar">
            <div class="topbar-item">
                <div @click="toggle"
                    aria-haspopup="true" aria-controls="overlay_menu"
                class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2">
                    <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                    <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{user.name}}</span>
                    <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
                        <span class="symbol-label font-size-h5 font-weight-bold">{{nameInitial(user.name)}}</span> 
                    </span>
                </div>
                 <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />

                <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="dashboard.html">Update Dashboard</a>
                    <a class="dropdown-item" href="login.html">Logout</a>
                </div> -->
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent, computed, inject, ref} from 'vue'
import Menu from 'primevue/menu';
export default defineComponent({
    components: { Menu },
    setup() {
		const { appRouter, router, route,  store, http, constants } = inject('App')
        const menu = ref();
        const items = ref([
            /*
            {
                label: 'Settings',
                command: () => {
                    localStorage.removeItem('token')
                    window.location = "/"
                }
            }, */
            {
                label: 'Logout',
                command: () => {
                    window.location = "/"   
                  //  store.dispatch('UserModule/LOGOUT', [constants, http])
                    localStorage.removeItem('token')
                }
            }
        ]);

        function toggle(event)  {
            menu.value.toggle(event);
        }

        function gotoDashboard() {
            appRouter.routeTo(route, router, 'Dashboard')
        }

        function nameInitial(string) {
            return string.charAt(0).toUpperCase();
          //  return string
        }

        return {
            user: computed(() => store.getters['UserModule/GET_USER']),
            school_year: computed(() => store.getters['UserModule/GET_ACTIVE_SCHOOL_YEAR']),
            route, items, menu, nameInitial,
            gotoDashboard,
            toggle
        }
    },
})
</script>
